import { useContext } from "react";
import PropTypes from "prop-types";
import { LuxeContext } from "../../context/LuxeProvider";
import { useAuth } from "../../context/AuthProvider";
import luxePerks from "./luxeSelection/perks.json";

import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import useDiscountsInfo, { DISCOUNT_INFO_MODES } from "../../hooks/useDiscountsInfo";
import { useSubscriptionPopup } from "../../context/SubscriptionPopupProvider";

import LuxeCheck from "../icons/svgs/luxe_check.svg";
import LuxeUncheck from "../icons/svgs/luxe_uncheck.svg";
import LuxeSelected from "../icons/svgs/luxe_selected.svg";
import LuxeUnselected from "../icons/svgs/luxe_unselected.svg";
import IconAccount from "../icons/svgs/account.svg";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import "./luxeSelection/styles.scss";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

function LuxeSelection({ sideCartInfo }) {
  const { luxe, setLuxeActive } = useContext(LuxeContext);
  const luxeDiscountsInfo = useDiscountsInfo(DISCOUNT_INFO_MODES.LUXE_ONLY);
  const nonLuxeDiscountsInfo = useDiscountsInfo(DISCOUNT_INFO_MODES.NON_LUXE_ONLY);
  const { setSubscriptionPopupOpen } = useSubscriptionPopup();

  const { showLogin, accountDetails } = useAuth();
  const formatCurrency = useCurrentFormatCurrency();
  const isLuxe = luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC;
  // TODO: Update for other discount type (% off, ...)
  const tierDiscountNonLuxe = nonLuxeDiscountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  const maxTieredDiscountNonLuxe =
    tierDiscountNonLuxe?.thresholdResultPercentage?.[
      tierDiscountNonLuxe?.thresholdResultPercentage?.length - 1
    ] || 0;
  const tierDiscountLuxe = luxeDiscountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  const maxTieredDiscountLuxe =
    tierDiscountLuxe?.thresholdResultPercentage?.[
      tierDiscountLuxe?.thresholdResultPercentage?.length - 1
    ] || 0;
  if (!luxe.isLuxeEnabled) return null;

  return luxe.customerSubscription &&
    (luxe.customerSubscription.status === "ACTIVE" ||
      luxe.customerSubscription.status === "PAUSE") ? (
    <div className="cart_luxe">
      <p className="cart_luxe_detail">AL Luxe Member - Extra 5% off</p>
      <p className="cart_luxe_balance">
        Balance: <b>${luxe.subscriptionCredit} in credits</b> (apply at checkout)
      </p>
    </div>
  ) : (
    <div className="perks">
      <div className="perks_table">
        <div className="perks_table_tr perks_table_tr--mostpopular">
          <div className="perks_table_th">&nbsp;</div>
          <div className="perks_table_luxe">
            <div className="perks_table_luxe--mostpopular">Best savings</div>
          </div>
          <div className="perks_table_nonmember">&nbsp;</div>
        </div>
        {luxePerks.perks.map((perk, i) => (
          <div
            className={`perks_table_tr perks_table_tr--${perk.type}`}
            key={`perk_${perk.type}_${i}`}
          >
            <div className="perks_table_th">
              {perk.segments?.map((segment, index) => (
                <span key={index} className={segment.bold ? "bold" : ""}>
                  {segment.text}
                </span>
              ))}
            </div>
            <div
              className={`perks_table_luxe${isLuxe ? " perks_table_luxe--active" : ""} microtext`}
              onClick={() => setLuxeActive(true, luxe.subscriptionPlan, sideCartInfo)}
            >
              {typeof perk.al_luxe_member === "boolean" ? (
                <LuxeCheck
                  stroke={`${isLuxe ? "#FBF7EC" : "#2D2927"}`}
                  width="11px"
                  height="11px"
                />
              ) : perk.al_luxe_member === "AL LUXE" ? (
                <>
                  {isLuxe ? (
                    <LuxeSelected className="perks_table_luxe_select" width="19px" height="19px" />
                  ) : (
                    <LuxeUnselected
                      className="perks_table_luxe_select"
                      width="19px"
                      height="19px"
                    />
                  )}
                  {perk.al_luxe_member}
                </>
              ) : perk.type === "footer" ? (
                <div className="perks_table_luxe_price">
                  {formatCurrency(sideCartInfo.finalAmountLux)}
                </div>
              ) : (
                perk.al_luxe_member.replace("{0}", maxTieredDiscountLuxe)
              )}
            </div>
            <div
              className={`perks_table_nonmember${
                !isLuxe ? " perks_table_nonmember--active" : ""
              } microtext`}
              onClick={() => setLuxeActive(false, luxe.subscriptionPlan, sideCartInfo)}
            >
              {typeof perk.non_member === "boolean" ? (
                <LuxeUncheck
                  stroke={`${!isLuxe ? "#E94B2F" : "#2D2927"}`}
                  width="11px"
                  height="11px"
                />
              ) : perk.non_member === "GUEST" ? (
                <>
                  {!isLuxe ? (
                    <LuxeSelected className="perks_table_luxe_select" width="19px" height="19px" />
                  ) : (
                    <LuxeUnselected
                      className="perks_table_luxe_select"
                      width="19px"
                      height="19px"
                    />
                  )}

                  {perk.non_member}
                </>
              ) : perk.type === "footer" ? (
                <div className="perks_table_nonmember_price">
                  {formatCurrency(sideCartInfo.finalAmount)}
                </div>
              ) : (
                perk.non_member.replace("{0}", maxTieredDiscountNonLuxe)
              )}
            </div>
          </div>
        ))}
      </div>

      {isLuxe && (
        <p className="perks_details_luxe">
          By purchasing, you choose to become an AL Luxe member and agree to the{" "}
          <a
            href="/pages/luxe-program-terms/"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Luxe Program Terms
          </a>
          , including a <b>$39.99</b> monthly payment converted to store credit. Use your credit as
          you wish, anytime. You will pay a <b>$1.95</b> fee for the first trial month.
        </p>
      )}

      <div className="perks_learn_more">
        <p className="perks_learn_more_cta" onClick={() => setSubscriptionPopupOpen(true)}>
          Learn more about AL Luxe membership
        </p>
        {!accountDetails && (
          <div className="perks_learn_more_signin" onClick={showLogin}>
            <IconAccount
              className="perks_learn_more_signin--account"
              height="13.5"
              stroke="#2D2927"
            />{" "}
            Sign In
          </div>
        )}
      </div>

      {isLuxe && (
        <p
          className="perks_details_luxe--optout"
          onClick={() => setLuxeActive(false, luxe.subscriptionPlan, sideCartInfo)}
        >
          To opt out, select "Guest"
        </p>
      )}
    </div>
  );
}

LuxeSelection.propTypes = {
  sideCartInfo: PropTypes.object.isRequired,
};

export default withALErrorBoundary({
  name: "SideCart",
  priority: "P1",
})(LuxeSelection);
