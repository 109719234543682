import { StaticImage } from "gatsby-plugin-image";
import { Popup } from "./Popup";
import { useSubscriptionPopup } from "../context/SubscriptionPopupProvider";
import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { ALButton } from "./ALComponents";

import IconClose from "./icons/svgs/close-round.svg";

import "./subscriptionPopUp/styles.scss";

function SubscriptionPopUp() {
  const { subscriptionPopupOpen, setSubscriptionPopupOpen } = useSubscriptionPopup();

  const handleClose = () => {
    setSubscriptionPopupOpen(false);
  };

  const popUpImage = useResponsiveWithHydrationFix(
    <StaticImage
      src="../images/subscription/popup-mobile.png"
      alt="Mobile Subscription PopUp image"
    />,
    <StaticImage
      src="../images/subscription/popup-desktop.png"
      alt="Desktop Subscription PopUp image"
    />
  );

  return (
    <Popup
      className="popup_subscription"
      open={subscriptionPopupOpen}
      closeOnDocumentClick
      onClose={handleClose}
    >
      <button
        type="button"
        data-testid="close-button"
        className="popup_close"
        onClick={handleClose}
      >
        <IconClose width="30" fill="none" stroke="#ffffff" />
      </button>
      <ALButton
        variant="primary"
        className="popup_button"
        onClick={handleClose}
      >
        Continue shopping
      </ALButton>
      {popUpImage}
    </Popup>
  );
}

export default withALErrorBoundary({
  name: "SubscriptionPopUp",
  priority: "P3",
})(SubscriptionPopUp);
