import { useContext, useMemo, useState, createContext } from "react";
import PropTypes from "prop-types";

export const SubscriptionPopupContext = createContext({});

export function useSubscriptionPopup() {
  return useContext(SubscriptionPopupContext);
}

export default function SubscriptionPopupProvider({ children }) {
  const [subscriptionPopupOpen, setSubscriptionPopupOpen] = useState(false);

  const popUpData = useMemo(
    () => ({
      subscriptionPopupOpen,
      setSubscriptionPopupOpen,
    }),
    [subscriptionPopupOpen, setSubscriptionPopupOpen]
  );

  return (
    <SubscriptionPopupContext.Provider value={popUpData}>
      {children}
    </SubscriptionPopupContext.Provider>
  );
}

SubscriptionPopupProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
